import React from "react";
import "./about.css";

const About = () => {
  return (
    <div className="about-container">
      <header className="about-header">
        <h1>Tentang Kami</h1>
        <p>
          Kenali lebih dekat Sedot WC Toiletku dan layanan profesional kami.
        </p>
      </header>
      <section className="about-content">
        <div className="company-info">
          <h2>Profil Perusahaan</h2>
          <p>
            Sedot WC Toiletku adalah penyedia layanan sedot WC terpercaya dengan
            pengalaman bertahun-tahun dalam industri. Kami menawarkan layanan
            pembuangan limbah yang cepat, efisien, dan profesional untuk
            memenuhi kebutuhan rumah tangga dan bisnis Anda.
          </p>
        </div>
        <div className="our-values">
          <h2>Nilai-Nilai Kami</h2>
          <ul>
            <li>
              <strong>Kualitas:</strong> Kami berkomitmen untuk memberikan
              layanan berkualitas tinggi dengan hasil yang memuaskan.
            </li>
            <li>
              <strong>Kepuasan Pelanggan:</strong> Kepuasan pelanggan adalah
              prioritas utama kami. Kami berusaha keras untuk memenuhi dan
              melampaui harapan Anda.
            </li>
            <li>
              <strong>Keandalan:</strong> Anda dapat mengandalkan kami untuk
              layanan yang tepat waktu dan andal setiap saat.
            </li>
            <li>
              <strong>Profesionalisme:</strong> Tim kami terdiri dari
              profesional berpengalaman yang siap membantu dengan sikap yang
              ramah dan profesional.
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default About;
