import React from "react";
import "./service.css"; // Menghubungkan dengan file CSS untuk styling

const Pelayanan = () => {
  return (
    <div className="service-container">
      <header className="service-header">
        <h1>Layanan Sedot WC Toiletku</h1>
        <p>
          Profesional, cepat, dan terpercaya untuk kebutuhan pembuangan Anda!
        </p>
      </header>

      <section className="service-intro">
        <h2>Kenapa Memilih Kami?</h2>
        <p>
          Kami menawarkan layanan sedot WC yang berkualitas tinggi dengan
          pengalaman bertahun-tahun dalam industri. Tim kami menggunakan
          peralatan canggih dan teknik terbaru untuk memastikan layanan yang
          cepat dan efisien.
        </p>
      </section>

      <section className="service-list">
        <div className="service-item">
          <h3>1. Sedot WC Rumah Tangga</h3>
          <p>
            Menyediakan layanan sedot WC untuk rumah tangga dengan proses yang
            bersih dan aman. Kami memastikan tidak ada bau yang tertinggal dan
            area sekitar tetap bersih.
          </p>
        </div>
        <div className="service-item">
          <h3>2. Sedot WC untuk Bisnis</h3>
          <p>
            Layanan sedot WC profesional untuk bisnis dan fasilitas umum. Kami
            memahami kebutuhan operasional bisnis dan siap memberikan layanan
            tanpa mengganggu aktivitas.
          </p>
        </div>
        <div className="service-item">
          <h3>3. Pembersihan Saluran dan Cegah Masalah</h3>
          <p>
            Tidak hanya sedot WC, kami juga menawarkan pembersihan saluran dan
            pemeliharaan sistem pembuangan untuk mencegah masalah di masa depan.
          </p>
        </div>
        <div className="service-item">
          <h3>4. Penanganan Limbah dengan Aman</h3>
          <p>
            Penanganan limbah dilakukan dengan prosedur yang sesuai dengan
            standar lingkungan dan regulasi untuk memastikan keamanan dan
            kepatuhan.
          </p>
        </div>
      </section>

      <section className="service-contact">
        <h2>Hubungi Kami untuk Layanan Cepat dan Terpercaya!</h2>
        <p>
          Siap untuk membantu Anda dengan kebutuhan sedot WC dan pembuangan
          limbah. Hubungi kami hari ini untuk mendapatkan penawaran dan
          jadwalkan layanan.
        </p>
        <button className="contact-button">Hubungi Kami</button>
      </section>
    </div>
  );
};

export default Pelayanan;
