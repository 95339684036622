import React, { useState } from "react";
import "./home.css"; // Menghubungkan dengan file CSS
import Contact from "../contact/contact";
import Foto1 from "../../components/assets/images/armada.jpg";
import Foto2 from "../../components/assets2/Screenshot 2024-09-13 120630.png";
import Foto3 from "../../components/assets2/Screenshot 2024-09-13 120732.png";
import Foto4 from "../../components/assets2/Screenshot 2024-09-13 120744.png";
import Foto5 from "../../components/assets2/Screenshot 2024-09-13 120759.png";
import Foto6 from "../../components/assets2/Screenshot 2024-09-13 120815.png";
import Foto7 from "../../components/assets2/Screenshot 2024-09-13 120827.png";
import Foto8 from "../../components/assets2/Screenshot 2024-09-13 120841.png";
import Foto9 from "../../components/assets2/Screenshot 2024-09-13 120853.png";
import Foto10 from "../../components/assets2/Screenshot 2024-09-13 120920.png";
import Foto from "../../components/assets2/Screenshot 2024-09-13 120931.png";
import Foto11 from "../../components/assets2/Screenshot 2024-09-13 120940.png";
import Foto12 from "../../components/assets2/Screenshot 2024-09-13 121003.png";
import Foto13 from "../../components/assets2/Screenshot 2024-09-13 121017.png";
import Foto14 from "../../components/assets2/Screenshot 2024-09-13 121038.png";
import Foto15 from "../../components/assets2/Screenshot 2024-09-13 121051.png";
import Foto16 from "../../components/assets/images/Penyedotan 7.jpg";
import Foto17 from "../../components/assets/images/limbah pabrik.jpg";
import Foto18 from "../../components/assets/images/pdtn 2.jpg";
import Foto19 from "../../components/assets/images/pelancaran saluran 2.jpg";
import Foto20 from "../../components/assets/images/penyedotan 6.jpg";

const Home = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const images = [
    Foto13,
    Foto14,
    Foto15,
    Foto16,
    Foto17,
    Foto18,
    Foto19,
    Foto20,
    Foto,
    Foto1,
    Foto2,
    Foto3,
    Foto4,
    Foto5,
    Foto6,
    Foto7,
    Foto8,
    Foto9,
    Foto10,
    Foto11,
    Foto12,
  ];

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  return (
    <div className="home-container">
      <header className="home-header">
        <h1>Sedot WC Toiletku</h1>
        <p>Sedot wc murah berpengalaman dan profesional.</p>
      </header>

      <div className="image-slider">
        <button className="slider-button prev" onClick={handlePrev}>
          ❮
        </button>
        <img
          src={images[currentImageIndex]}
          alt="Slider"
          className="slider-image"
        />
        <button className="slider-button next" onClick={handleNext}>
          ❯
        </button>
      </div>

      <section className="home-services">
        <h2>Mengapa Harus Memilih Kami:</h2>
        <div className="services-grid">
          <div className="service-card">
            <h3>
              Layanan Sedot WC Profesional untuk Kesehatan dan Kenyamanan Anda!
              🚽✨
            </h3>
            <p>
              Apakah WC Anda mulai bermasalah? Layanan Sedot WC kami siap
              membantu! Dengan pengalaman dan peralatan terbaru, kami mengatasi
              semua masalah pembuangan dengan cepat dan efisien. Hubungi kami
              untuk pelayanan berkualitas dan solusi yang andal!
            </p>
          </div>
          <div className="service-card">
            <h3>Masalah WC? Kami Punya Solusinya! 🛠️💧</h3>
            <p>
              Layanan Sedot WC kami hadir untuk memastikan sistem pembuangan
              Anda berfungsi optimal. Kami menawarkan layanan cepat dan
              profesional dengan harga yang bersaing. Tidak perlu khawatir lagi,
              percayakan masalah WC Anda kepada kami!
            </p>
          </div>
          <div className="service-card">
            <h3>Kenyamanan Rumah Anda adalah Prioritas Kami! 🚿🏠</h3>
            <p>
              Dengan Layanan Sedot WC kami, nikmati kebersihan dan kenyamanan
              tanpa gangguan. Kami menggunakan teknologi canggih dan tenaga ahli
              untuk membersihkan dan mengatasi masalah pembuangan WC Anda.
              Hubungi kami untuk solusi yang efektif dan terpercaya!
            </p>
          </div>
          <div className="service-card">
            <h3>Sedot WC Berkualitas untuk Rumah dan Bisnis Anda! 💼🚽</h3>
            <p>
              Layanan Sedot WC kami menyediakan layanan yang cepat dan bersih,
              ideal untuk kebutuhan rumah tangga maupun bisnis. Kami menjamin
              penanganan profesional dengan hasil memuaskan. Jangan biarkan
              masalah WC mengganggu aktivitas Anda—hubungi kami segera!
            </p>
          </div>
        </div>
        <Contact />
      </section>
    </div>
  );
};

export default Home;
