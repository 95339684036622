import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section about">
          <h4>About Us</h4>
          <p>
            Solusi terbaik untuk WC tersumbat dan masalah pipa di Tangerang!
            Dapatkan layanan sedot WC profesional dengan harga terjangkau.
            Hubungi kami sekarang untuk penjadwalan layanan!
          </p>
        </div>
        <div className="footer-section links">
          <h4>Quick Links</h4>
          <ul>
            <li>
              <a href="/">Beranda</a>
            </li>
            <li>
              <a href="/service">Pelayanan</a>
            </li>
            <li>
              <a href="/about">Tentang Kami</a>
            </li>
            <li>
              <a href="/contact">Kontak Kami</a>
            </li>
          </ul>
        </div>
        <div className="footer-section social">
          <h4>Follow Us</h4>
          <ul>
            <li>
              <a href="https://wa.me/6285776054243">WhatsApp</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Toiletid. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
