import React from "react";
import "./orderbuttom.css"; // Pastikan Anda memiliki file CSS yang terhubung

const OrderButton = () => {
  const handleWhatsAppRedirect = () => {
    const phoneNumber = "6281410920552"; // Ganti dengan nomor WhatsApp yang ingin dituju (tanpa tanda +)
    const message = "Halo, saya tertarik untuk memesan layanan Anda!"; // Pesan yang ingin dikirimkan
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    // Redirect ke WhatsApp
    window.location.href = url;
  };

  return (
    <button className="order-button" onClick={handleWhatsAppRedirect}>
      Hubungi Kami !!!
    </button>
  );
};

export default OrderButton;
