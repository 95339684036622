import React from "react";
import "./contact.css"; // Menghubungkan dengan file CSS

const Contact = () => {
  return (
    <div className="contact-container">
      <header className="contact-header">
        <h1>Hubungi Sedot WC Toiletku</h1>
        <p>
          Kami siap melayani Anda dengan cepat, profesional, dan harga
          terjangkau.
        </p>
      </header>

      <section className="contact-info">
        <h2>Informasi Kontak</h2>
        <div className="contact-details">
          <div className="detail-item">
            <h3>📞 Telepon</h3>
            <p>
              <a href="tel:+6281410920552">+62 814-1092-0552</a>
            </p>
            <p>
              <a
                href="https://wa.me/6281410920552"
                target="_blank"
                rel="noopener noreferrer"
              >
                Chat di WhatsApp
              </a>
            </p>
          </div>
          <div className="detail-item">
            <h3>Alamat</h3>
            <p>
              Permata Sepatan Jln. Safir 1 blok D2 No.26 Desa Pisang Jaya
              Kecamatan Sepatan Kabupaten Tangerang
            </p>
          </div>
        </div>
      </section>

      {/* Lokasi (optional, bisa di-uncomment jika ingin ditampilkan) */}
      {/* <section className="contact-map">
        <h2>Lokasi Kami</h2>
        <iframe
          title="Lokasi Sedot WC Toiletku"
          src="https://www.google.com/maps/search/Permata+Sepatan+Jln.+Safir+1+blok+D2+No.26+Desa+Pisang+Jaya+Kecamatan+Sepatan+Kabupaten+Tangerang/@-6.122998,106.5351435,13190m/data=!3m2!1e3!4b1?entry=ttu&g_ep=EgoyMDI0MDkxOC4xIKXMDSoASAFQAw%3D%3D"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen={false}
          loading="lazy"
        ></iframe>
      </section> */}
    </div>
  );
};

export default Contact;
