import React, { useState } from "react";
import "./navbar.css";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="logo">ToiletKu Sedot Wc</div>
      <div className={`nav-links ${isOpen ? "open" : ""}`}>
        <a href="/" onClick={toggleMenu}>
          Beranda
        </a>
        <a href="/about" onClick={toggleMenu}>
          Tentang Kami
        </a>
        <a href="/service" onClick={toggleMenu}>
          Pelayanan
        </a>
        <a href="/contact" onClick={toggleMenu}>
          Kontak Kami
        </a>
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        {isOpen ? (
          <span className="close">X</span>
        ) : (
          <span className="bars">☰</span>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
